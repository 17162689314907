<template>
  <div id="app">
    <h1>Iniciar Sesión</h1>

    <!-- Formulario de Usuario y Contraseña -->
    <form @submit.prevent="login">
      <div>
        <label for="email">Correo Electrónico</label>
        <input type="email" v-model="email" id="email" required />
      </div>

      <div>
        <label for="password">Contraseña</label>
        <input type="password" v-model="password" id="password" required />
      </div>

      <button type="submit">Iniciar Sesión</button>
    </form>

    <!-- Mensaje de error -->
    <p v-if="errorMessage" style="color: red;">{{ errorMessage }}</p>

    <!-- Botón de Google Sign-In -->
    <div id="g_id_onload"
         data-client_id="257142636186-88d1runssdedtoat3hdvebvoiknd95de.apps.googleusercontent.com"
         data-callback="handleCredentialResponse"
         data-auto_prompt="false">
    </div>
    <div class="g_id_signin" data-type="standard"></div>

    <!-- Botón de LinkedIn Sign-In -->
    <div>
      <button @click="loginWithLinkedIn" class="linkedin-button">Iniciar sesión con LinkedIn</button>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'HomePage',
  data() {
    return {
      email: '',
      password: '',
      errorMessage: ''
    };
  },
  methods: {
    async login() {
      try {
        // 1. Obtener el token CSRF
        await axios.get('https://virtualent.io/sanctum/csrf-cookie', {
          withCredentials: true,
        });

        // 2. Obtener valor del token CSRF
        const getCookieValue = (name) => {
          const value = `; ${document.cookie}`;
          const parts = value.split(`; ${name}=`);
          if (parts.length === 2) return parts.pop().split(';').shift();
        };

        const xsrfToken = getCookieValue('XSRF-TOKEN');

        if (!xsrfToken) {
          throw new Error('CSRF token no encontrado en las cookies.');
        }

        console.log('CSRF Token:', xsrfToken); // Depuración

        // 3. Realizar la solicitud de login con usuario y contraseña
        const response = await axios.post(
          'https://virtualent.io/login',
          {
            email: this.email,
            password: this.password,
          },
          {
            withCredentials: true,
            headers: {
              'X-XSRF-TOKEN': xsrfToken,
              'X-CSRF-TOKEN': xsrfToken,
            },
          }
        );

        console.log('Login exitoso:', response);

        this.$router.push('/about');
      } catch (error) {
        console.error('Error en el inicio de sesión:', error);
        this.errorMessage = 'Error en el inicio de sesión. Verifica tus credenciales.';
      }
    },

    // Método para manejar la respuesta de Google Sign-On
    async handleCredentialResponse(response) {
      try {
        const googleToken = response.credential;  // El token de Google

        // Enviar el token de Google al backend para autenticar
        const loginResponse = await axios.post('https://virtualent.io/api/login-google', {
          token: googleToken,
        });

        const { access_token } = loginResponse.data;

        // Guardar el token en localStorage y establecer encabezados para futuras solicitudes
        localStorage.setItem('token', access_token);
        axios.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;

        // Redirigir al dashboard
        this.$router.push('/about');
      } catch (error) {
        console.error('Error en el inicio de sesión con Google:', error);
        this.errorMessage = 'Error en el inicio de sesión con Google.';
      }
    },

    // Método para manejar el inicio de sesión con LinkedIn
    loginWithLinkedIn() {
      // Redirigir a la ruta de autenticación con LinkedIn en el backend
      window.location.href = 'https://virtualent.io/auth/linkedin';
    }
  },
  mounted() {
    // Exponer la función handleCredentialResponse en el contexto global
    window.handleCredentialResponse = this.handleCredentialResponse;
  },
  created(){
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');

    if (token) {
      // Guardar el token en localStorage y establecer encabezados para futuras solicitudes
      localStorage.setItem('token', token);
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

      // Redirigir al dashboard
      this.$router.push('/about');
    }
  }
};
</script>

<style scoped>
#app {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
}

div {
  margin-bottom: 20px;
}

button {
  padding: 10px 20px;
  background-color: #42b983;
  border: none;
  color: white;
  cursor: pointer;
}

button:hover {
  background-color: #38a371;
}

.linkedin-button {
  background-color: #0077b5;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  margin-top: 10px;
}

.linkedin-button:hover {
  background-color: #005582;
}

/* Google Sign-In button styles can be customized here if needed */
</style>
