import { createRouter, createWebHistory } from 'vue-router';
import Home from './views/Home.vue';  // Importación directa para la página de inicio

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: Home,  // Componente de inicio de sesión
  },
  {
    path: '/about',
    name: 'AboutPage',
    component: () => import('./views/AboutPage.vue'),  // Importación dinámica para mejorar el rendimiento
    meta: {
      requiresAuth: true,  // Esta ruta requiere autenticación
    },
  },
  {
    path: '/linkedin/callback',
    name: 'LinkedInCallback',
    component: Home, // O un componente similar para manejar la redirección
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// Guardián de navegación para proteger rutas
router.beforeEach((to, from, next) => {
  const isAuthenticated = !!localStorage.getItem('token');

  if (to.matched.some(record => record.meta.requiresAuth) && !isAuthenticated) {
    next('/'); // Redirigir a la página de inicio de sesión
  } else {
    next(); // Continuar si está autenticado o si no se requiere autenticación
  }
});

export default router;
